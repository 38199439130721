import React from 'react';

const NurilIslamSchool = () => {
  return (
    <div>
      <section>
        <h2>Curriculum</h2>
        <p>Content for Curriculum</p>
      </section>
      <section>
        <h2>Admission Information</h2>
        <p>Content for Admission Information</p>
      </section>
      <section>
        <h2>Faculty/Staff</h2>
        <p>Content for Faculty/Staff</p>
      </section>
      <section>
        <h2>Announcement/Event</h2>
        <p>Content for Announcement/Event</p>
      </section>
      <section>
        <h2>Contact Information</h2>
        <p>Content for Contact Information</p>
      </section>
    </div>
  );
};

export default NurilIslamSchool;
